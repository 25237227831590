var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"calculate-widths":"","items":_vm.items,"expanded":_vm.expanded,"item-key":"id","show-expand":"","options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page},"hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":function (ref) {
	var value = ref.value;
	var item = ref.item;

	return value && _vm.fetchSubTable(item);
},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"item.isMandatory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.isMandatory ? _vm.$_strings.common.YES : _vm.$_strings.common.NO)+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.createdAt))+" ")])]}},{key:"item.actionColumn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary lighten-1","small":"","to":("/main/kategori/edit/" + (item.id))}},[_vm._v(" ubah ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var subItem = ref.item;
return [(subItem.subItemTableLoading)?[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1)]:(subItem.subItemTable)?[_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":headers,"items":subItem.subItemTable,"item-key":"name","hide-default-header":"","hide-default-footer":"","items-per-page":subItem.subItemTable.length},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(subHeader,index){return _c('th',{key:index,style:({width: subHeader.width})})})}},{key:"item.descriptions",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.attachmentUrl)?_c('a',{on:{"click":function($event){return _vm.openDocument(item)}}},[_vm._v(_vm._s(item.attachmentName))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.attachmentName))])])]}}],null,true)})],1)]:_vm._e()]}}],null,true)}),_c('dialog-view-image',{attrs:{"visible":_vm.dialogViewImage,"imageUrl":_vm.imageSelected},on:{"setDialog":_vm.setDialog}}),_c('dialog-view-pdf',{attrs:{"visible":_vm.dialogViewPdf,"urlPdf":_vm.urlPdfSelected},on:{"setDialog":_vm.setDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }